import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/spp',
    name: 'spp',
    component: () => import('../views/SppView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/kartu-ujian',
    name: 'kartu',
    component: () => import('../views/KartuUjian.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/prakerin',
    name: 'prakerin',
    component: () => import('../views/PrakerinView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/prakerin/aktifitas',
    name: 'AktifitasPrakerin',
    component: () => import('../views/AktivitasPrakerin.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/konseling',
    name: 'konseling',
    component: () => import('../views/KonselingView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/data-diri',
    name: 'biodata',
    component: () => import('../views/DataDiri.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/keluhan',
    name: 'keluhan',
    component: () => import('../views/KeluhanView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
  {
    path: '/pinjam-ruangan',
    name: 'pinjam',
    component: () => import('../views/PinjamView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'login'
        })
      }
      next();
    },

  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
