import axios from 'axios'
import { defineStore } from 'pinia'
export const useKontrol = defineStore('kontrol', {
    state:()=>{
        return{
            url:'https://sistelk.id',
            // url:'http://127.0.0.1:8000/',
            status:false,
            gupegs:"",
            message:{
                id:"",
                komentar:"",
                pesan_id:"",
            },
            tingkat:"",
            pejabat:""
        }
    },
    actions:{
        // async getuser(){
        //     const response=await axios.get('inuser');
        //         this.users=response.data
        // }
       async getTingkat(){
        const response=await axios.get('cektingkat');
        this.tingkat=response.data.ket
       },
       async getGupeg(){
        const reseponse=await axios.get('gupegs');
        this.gupegs=reseponse.data
       },
       async getPejabat(kode){
        const reseponse=await axios.get('pejabat/'+kode);
        this.pejabat=reseponse.data
       }
    }
  })