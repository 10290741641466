<template>
  <q-page>
    <img src="../assets/wave.png" class="wave"/>
    <div class="flex justify-end">
      <img src="../assets/smk_telkom_mksfix.png" style="max-width: 120px;" class="q-mt-lg q-mr-lg"/>
    </div>
    <div class="row" style="height:90vh">
      <div class="col-0 col-md-6 flex justify-center content-center">
        <img src="../assets/login2.png" class="responsive"/>
      </div>
      <div v-bind:class="{'justify-center': $q.screen.md || $q.screen.sm ||$q.screen.xs}" class="col-12 col-md-6 flex content-center">
        <q-card v-bind:style="$q.screen.lt.sm ? {'width': '80%'} : {'width': '50%'}">
          <q-card-section>
            <q-avatar size="103px" class="absolute-center shadow-10">
              <q-img src="../assets/Sehati.png" style="width: 100px;"/>
            </q-avatar>
          </q-card-section>
          <q-card-section>
            <div class="q-pt-lg">
              <div class="col text-h6 ellipsis flex justify-center">
                <h4 class="text-h4 text-uppercase q-my-none text-weight-regular">Stelkers Login</h4>
              </div>
            </div>
          </q-card-section>
          <q-card-section>
            <q-form class="q-gutter-md">
              <q-input label="Username" v-model="form.username">
              </q-input>
              <q-input label="Password" type="password" v-model="form.password">
              </q-input>
              <div>
                <q-btn class="full-width" @click="submit" color="red-9">Login</q-btn>
              </div>
            </q-form>
            <div class="q-my-lg">
              <div class="text-center text-grey-6">
                Powered By Sistelk Project
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import { useKontrol } from '@/store/kontrol';
import { onMounted } from 'vue';
import { mapActions } from "vuex";
export default {
name:'LoginView',
setup(){
    const kontrol=useKontrol()
    onMounted(()=>{
        kontrol.nama='semangat pagi'
    })
    return{
        kontrol
    }
},
data:()=>({
    form:{
        username:"",
        password:"",
    }
}),
methods:{
    ...mapActions({
      signIn: "auth/signIn",
    }),
    submit() {
      this.signIn(this.form).then(() => {
        this.$router.replace({
          name: "home",
        });
      })
    },
}
}
</script>

<style>
.wave{
  position: fixed;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}
</style>