import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { createPinia } from 'pinia'
const pinia = createPinia()
import axios from 'axios'
axios.defaults.baseURL='https://sistelk.id/api/'
// axios.defaults.baseURL='http://127.0.0.1:8000/api/'
require ('@/store/subscriber')
store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{
createApp(App).use(Quasar, quasarUserOptions)
.use(store)
.use(pinia)
.use(router)
.mount('#app')
})
