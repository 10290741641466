<template>
  <q-layout view="lHh Lpr lFf">
    <q-header v-if="authenticated" bordered class="bg-white text-primary small-screen-only">
        <q-toolbar>
          <q-toolbar-title class="text-center">
            <img src="../src/assets/stelkers.png" style="max-width:100px"/>
          </q-toolbar-title>
        </q-toolbar>
      </q-header>
      <q-drawer
        v-if="authenticated"
        v-model="drawer"
        show-if-above
        :mini="!drawer || miniState"
        @click.capture="drawerClick"
        :width="250"
        :breakpoint="600"
        bordered
      >
        <template v-slot:mini>
            <div class="q-py-lg">
              <div class="column items-start">
                <q-avatar style="margin: auto;">
                  <img src="../src/assets/ts.jpg" style="max-width: 50px;"/>
                </q-avatar>
                <q-icon name="home" class="mini-icon q-mt-sm" />
                <q-icon name="manage_accounts" class="mini-icon" />
                <q-icon name="auto_stories" class="mini-icon" />
                <q-icon name="mdi-bitcoin" class="mini-icon" />
                <q-icon name="mdi-worker" class="mini-icon" />
                <q-icon name="mdi-cast-connected" class="mini-icon" />
                <q-icon name="mdi-alert-rhombus-outline" class="mini-icon" />
                <q-icon name="mdi-biohazard" class="mini-icon" />
                <q-icon name="mdi-smart-card-outline" class="mini-icon" />
                <q-icon name="mdi-home-modern" class="mini-icon" />
                <q-icon name="logout" class="mini-icon" />
              </div>
            </div>
        
        </template>
        <div class="flex justify-center q-mt-lg">
           <img src="../src/assets/smk_telkom_mksfix.png" style="max-width: 130px;"/>
        </div>
      
          <q-list padding>
            <q-item clickable to="/" v-ripple active-class="my-menu-link">
              <q-item-section avatar>
                <q-icon name="home"/>
              </q-item-section>
              <q-item-section>
                Home
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple active-class="my-menu-link" to="/user">
              <q-item-section avatar>
                <q-icon name="manage_accounts"/>
              </q-item-section>
              <q-item-section>
                User
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple active-class="my-menu-link" to="/data-diri">
              <q-item-section avatar>
                <q-icon name="auto_stories"/>
              </q-item-section>
              <q-item-section>
                Data Diri
              </q-item-section>
            </q-item>
            <q-item clickable active-class="my-menu-link" v-ripple to="/spp">
              <q-item-section avatar>
                <q-icon name="mdi-bitcoin"/>
              </q-item-section>
              <q-item-section>
                SPP
              </q-item-section>
            </q-item>
            <q-item clickable active-class="my-menu-link" v-ripple to="/prakerin">
              <q-item-section avatar>
                <q-icon name="mdi-worker"/>
              </q-item-section>
              <q-item-section>
                Daftar Prakerin
              </q-item-section>
            </q-item>
            <q-item clickable active-class="my-menu-link" v-ripple to="/prakerin/aktifitas">
              <q-item-section avatar>
                <q-icon name="mdi-cast-connected"/>
              </q-item-section>
              <q-item-section>
                Aktifitas Prakerin
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple active-class="my-menu-link" to="/konseling">
              <q-item-section avatar>
                <q-icon name="mdi-alert-rhombus-outline"/>
              </q-item-section>
              <q-item-section>
                Konseling
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple active-class="my-menu-link" to="/keluhan">
              <q-item-section avatar>
                <q-icon name="mdi-biohazard"/>
              </q-item-section>
              <q-item-section>
                Keluhan
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple active-class="my-menu-link" to="/kartu-ujian">
              <q-item-section avatar>
                <q-icon name="mdi-smart-card-outline"/>
              </q-item-section>
              <q-item-section>
                Kartu Ujian
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple active-class="my-menu-link" to="/pinjam-ruangan">
              <q-item-section avatar>
                <q-icon name="mdi-home-modern"/>
              </q-item-section>
              <q-item-section>
                Peminjaman Ruangan
              </q-item-section>
            </q-item>

            <q-item clickable v-ripple @click="logout">
              <q-item-section avatar>
                <q-icon name="logout"/>
              </q-item-section>
              <q-item-section>
                Logout
              </q-item-section>
            </q-item>
          </q-list>

        <div class="q-mini-drawer-hide absolute" style="top: 15px; right: -17px">
          <q-btn
            dense
            round
            color="white"
            text-color="gery-10"
            icon="chevron_left"
            @click="miniState = true"
          />
        </div>
      </q-drawer>
      <q-footer v-if="authenticated" bordered class="bg-white small-screen-only">
        <q-tabs no-caps active-color="primary" indicator-color="transparent" class="text-grey-10">
          <q-route-tab to="/" icon="home" />
          <q-route-tab icon="widgets" @click="dialogMenu=true" />
          <q-route-tab to="/user" icon="manage_accounts" />
        </q-tabs>
      </q-footer>
    <q-page-container>
      <router-view/>
    </q-page-container>
    <!-- DIALOG MENU -->
    <q-dialog
      v-model="dialogMenu"
      :maximized="maximizedToggle"
      transition-show="slide-right"
      transition-hide="slide-left"
    >
    <q-card>
      <q-toolbar>
        <q-toolbar-title>Menu</q-toolbar-title>
        <q-space/>
        <q-btn icon="chevron_left" round @click="dialogMenu=false" flat/>
      </q-toolbar>
      <q-card-section>
        <q-list>
          <q-item clickable v-ripple to="/" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="home" color="primary" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Home</q-item-label>
              <q-item-label caption>Home Page</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/data-diri" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="auto_stories" color="secondary" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Data Diri</q-item-label>
              <q-item-label caption>Buku Induk</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/spp" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="paid" color="indigo" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">SPP</q-item-label>
              <q-item-label caption>Sumbangan Pembinaan Pendidikan</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/konseling" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="warning" color="red" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Poin Pelanggaran</q-item-label>
              <q-item-label caption>Bimbingan Konseling</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/kartu-ujian" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="badge" color="teal" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Kartu Ujian</q-item-label>
              <q-item-label caption>Ujian Sekolah</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/prakerin" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="badge" color="teal" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Daftar Prakerin</q-item-label>
              <q-item-label caption>Registrasi</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/prakerin/aktifitas" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="mdi-cast-connected" color="teal" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Aktifitas Prakerin</q-item-label>
              <q-item-label caption>Jurnal Kegiatan</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple to="/pinjam-ruangan" @click="dialogMenu=false" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="event" color="purple" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Peminjaman Ruangan</q-item-label>
              <q-item-label caption>Kegiatan Extrakurikuler</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
          <q-item clickable v-ripple  @click="logout" active-class="my-menu-link">
            <q-item-section avatar top>
              <q-avatar icon="logout" color="teal" text-color="white" />
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Logout</q-item-label>
              <q-item-label caption>from stelkers</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon name="chevron_right" color="green" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import { mapGetters,mapActions } from "vuex";
export default {
  name: 'LayoutDefault',
  setup () {
    const miniState = ref(true)
    const dialogMenu=ref(false)
    return {
      drawer: ref(false),
      maximizedToggle: ref(true),
      miniState,
      drawerClick (e) {
        if (miniState.value) {
          miniState.value = false
          e.stopPropagation()
        }
      },
      dialogMenu,
    }
  },
  computed:{
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods:{
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },
  }
}
</script>
<style lang="sass" scoped>
.q-toolbar
  @media (min-width:  $breakpoint-sm-min)
    height: 77px
.mini-slot
  transition: background-color .28s
  &:hover
    background-color: rgba(0, 0, 0, .04)

.mini-icon
  font-size: 1.718em
  padding: 2px 16px

  & + &
    margin-top: 18px

.my-menu-link
  border-right:4px solid #B71C1C
</style>